/* Footer.css */

.footer-wrapper {
  
  bottom: 0;
  width: 100%;
  background-color: #f8f8f8;

  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.1);
}

#footer {
  background-color: #303030;
  padding: 20px 0;
  text-align: center;
}

.container1 {
  /* max-width: 960px; */
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

/* Home.css (혹은 해당 홈 컴포넌트의 스타일 파일) */


